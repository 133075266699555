<template>
  <Layout>
    <template v-slot:label>
      {{ row.label }}
    </template>

    <template v-slot:extra>
      {{ row.extra }}
    </template>

    <template v-slot:input>
      <select
        v-model="model"
        class="px-2 py-1 text-xs text-gray-100 bg-gray-600 border-gray-800 rounded-sm form-select text-sans"
      >
        <template v-for="(label, value) in row.values">
          <option :value="value">
            {{ label }}
          </option>
        </template>
      </select>
    </template>
  </Layout>
</template>
<script>
import inputs from './InputMixin'

export default {
  name: 'SelectInput',

  mixins: [inputs],

  data () {
    return {
      model: this.row.value
    }
  }
}

</script>
